import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NewsPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="News" />
      <h1>News</h1>
      <p>
        I'll publish something new hopefully soon, but in the meanwhile, feel
        free to browse the news <Link to="/news/archive/1">archive</Link>.
      </p>
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  )
}
export default NewsPage
